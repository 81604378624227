import ReactGA from 'react-ga';

import { isProduction } from 'src/utils/environmentUtils';

export const initializeGoogleAnalytics = () => {
  if (isProduction()) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, { debug: true });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
};
