// React-Cookies
import cookie from 'react-cookies';

// Utils
import subHours from 'date-fns/subHours';
import compareAsc from 'date-fns/compareAsc';
import parseISO from 'date-fns/parseISO';
import { appConfig } from 'src/config/config';
import logger from 'src/logging/logger';
import { getVerboseLogMsg } from 'src/utils/loggingUtils';

// Constants
const {
  userActivityDateTime,
  userActivityExpireHours,
  userHasLoggedIn,
} = appConfig.cookies;
const componentName = 'cookieUtils';

export const getUserActivityCookieExpiration = () => subHours(new Date(), Number(userActivityExpireHours));

export const setWithUserActivityExpiration = (key: string, value: any) => {
  cookie.save(
    key,
    value,
    {
      path: '/',
      expires: getUserActivityCookieExpiration(),
      maxAge: 12 * 60 * 60 * 1000, // 12 hours
      domain: window.location.hostname
    }
  );
};

export const setWithNoExpiration = (key: string, value: any) => {
  cookie.save(
    key,
    value,
    {
      path: '/',
      domain: window.location.hostname
    }
  );
};

export const getCookie = (key: string) => cookie.load(key, true);

export const removeCookie = (key: string) => cookie.remove(key);

export const getCookieParsed = (key: string) => cookie.load(key, false);

export const cookieExists = (key: string) => cookie.load(key) !== undefined;

export const deleteCookie = (key: string) => cookie.remove(key);

export const getUserActivityDateTime = (): Date | null => {
  const activityDate = cookie.load(userActivityDateTime, false) as string;
  logger.verbose(getVerboseLogMsg(componentName, 'getUserActivityDateTime', 'activityDate'), activityDate);
  if (!activityDate) return null;
  return parseISO(activityDate);
};

export const getUserActivityDateTimeAsString = (): string | null => {
  const activityDate = cookie.load(userActivityDateTime, false) as string;
  logger.verbose(getVerboseLogMsg(componentName, 'getUserActivityDateTime', 'activityDate'), activityDate);
  if (!activityDate) return 'not set';
  return new Date(activityDate).toLocaleString();
};

export const unconditionallySetUserActivityDateTime = () => {
  const newActivityDate = new Date();
  logger.verbose(`setting initial user activity timestamp: ${newActivityDate}`);
  setWithUserActivityExpiration(userActivityDateTime, newActivityDate);
};

export const updateUserActivityDateTime = () => {
  // only update the user activity cookie if it has already been set
  logger.verbose(getVerboseLogMsg(componentName, 'updateUserActivityDateTime', 'called'));
  const userActivityCookie = getUserActivityDateTime();
  if (!userActivityCookie) return;
  unconditionallySetUserActivityDateTime();
};

export const removeUserActivityDateTime = () => {
  let userActivityCookie = getUserActivityDateTime();
  logger.verbose('removeUserActivityDateTime (before): ', userActivityCookie);
  cookie.remove(userActivityDateTime);
  userActivityCookie = getUserActivityDateTime();
  logger.verbose('removeUserActivityDateTime (after): ', userActivityCookie);
};

const activityCookieExpired = (userActivityCookie: Date) => {
  logger.verbose(getVerboseLogMsg(componentName, 'activityCookieExpired', 'userActivityExpireHours: '), userActivityExpireHours);
  const expiredDateTime = subHours(new Date(), userActivityExpireHours);
  logger.verbose(getVerboseLogMsg(componentName, 'activityCookieExpired', 'expiredDateTime: '), expiredDateTime);
  logger.verbose(getVerboseLogMsg(componentName, 'activityCookieExpired', 'compareAsc: '), compareAsc(expiredDateTime, userActivityCookie) > -1);
  const compare = compareAsc(expiredDateTime, userActivityCookie) > -1;
  return compare;
};

export const isUserActivityNull = (): boolean => {
  const userActivityCookie = getUserActivityDateTime();
  logger.verbose(getVerboseLogMsg(componentName, 'isUserActivityNull', 'userActivityCookie === null?: '), userActivityExpireHours);
  logger.verbose(getVerboseLogMsg(componentName, 'isUserActivityNull', 'userActivityCookie === undefined?: '), userActivityExpireHours);
  logger.verbose(getVerboseLogMsg(componentName, 'isUserActivityNull', 'userActivityCookie === null || userActivityCookie === undefined?: '), userActivityCookie === null || userActivityCookie === undefined);
  logger.verbose('userActivityCookie === null? ', userActivityCookie === null);
  logger.verbose('userActivityCookie === undefined? ', userActivityCookie === undefined);
  logger.verbose('userActivityCookie === null || userActivityCookie === undefined? ', userActivityCookie === null || userActivityCookie === undefined);
  return userActivityCookie === null || userActivityCookie === undefined;
};

export const isUserActivityExpired = (): boolean => {
  const userActivityCookie = getUserActivityDateTime();
  if (!userActivityCookie) return false; // we only want to check if it is expired
  return activityCookieExpired(userActivityCookie);
};

export const isUserLoggedIn = (): boolean => {
  const userLoggedIn = getCookie(userHasLoggedIn);
  if (!userLoggedIn) return false;
  return userLoggedIn;
};

export const setUserLoggedIn = () => setWithNoExpiration(userHasLoggedIn, true);

export const setUserLoggedOut = () => deleteCookie(userHasLoggedIn);
