import { appConfig } from 'src/config/config';
import { getContentfulLanguageFromi18nLanguage } from './languageUtils';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';

/**
 * TODO: describe function
 * @author Kevin Parkinson
 * @param TODO: fill out params
*/
export const getGreetingTranslationKeyForTimeOfDay = (): string => {
  const curHr = new Date().getHours();
  const { GoodMorning, GoodAfternoon, GoodEvening } = appConfig.i18n.messages;
  let greeting = '';

  if (curHr < 12) greeting = GoodMorning;
  else if (curHr < 18) greeting = GoodAfternoon;
  else greeting = GoodEvening;
  return greeting;
};

const standardFormatDate = (d: Date, language: string) => {
  const lang = getContentfulLanguageFromi18nLanguage(language);
  return `${d.toLocaleDateString(lang, { weekday: 'short' })} ${d.toLocaleString(lang, { month: 'short' })} ${d.getDate()} ${d.getFullYear()}`;
};

const standard12HourAMPMFormat = (d: Date) => `${d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;

const standardHHMMss = (d: Date) => `${(`0${d.getHours()}`).slice(-2)}:${(`0${d.getMinutes()}`).slice(-2)}:${(`0${d.getSeconds()}`).slice(-2)}`;

const standardYMDFormatDate = (d: Date) => `${d.getFullYear()}-${(`0${d.getMonth() + 1}`).slice(-2)}-${(`0${d.getDate()}`).slice(-2)}`;

const standardYMHMSFormatDate = (d: Date) => `${d.getFullYear()}-${(`0${d.getMonth() + 1}`).slice(-2)}-${(`0${d.getDate()}`).slice(-2)} ${(`0${d.getHours()}`).slice(-2)}:${(`0${d.getMinutes()}`).slice(-2)}:${(`0${d.getSeconds()}`).slice(-2)}`;

export const standardURLFormatDate = (d: string) => {
  const date = new Date(Date.parse(d));
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

/**
 * get a date as string in standard format
 * @author Kevin Parkinson
 * @param {string} date - the date  you wish  to format
 * @param {string} language - the i18n language
*/
export const getDateInStandardFormat = (date: string, language: string): string => standardFormatDate(new Date(Date.parse(date)), getContentfulLanguageFromi18nLanguage(language));

/**
 * get a date as string in standard format
 * @author John Andaya
 * @param {string} date - the date  you wish  to format
*/
export const getDateInStandardYMDHMSFormat = (date: string): string => standardYMHMSFormatDate(new Date(Date.parse(date)));

/**
 * get a date as string in standard format
 * @author John Andaya
 * @param {string} date - the date  you wish  to format
*/
export const getDateInStandardHHmmssFormat = (date: string): string => standardHHMMss(new Date(Date.parse(date)));

/**
 * get a date as string in HH:mm:ss format
 * @author John Andaya
 * @param {string} date - the date  you wish  to format
*/
export const getDateInStandard12HourAMPMFormat = (date: string): string => standard12HourAMPMFormat(new Date(Date.parse(date)));

/**
 * get a date as string in standard format
 * @author Kevin Parkinson
 * @param {string} date - the date  you wish  to format
 * @param {string} language - the i18n language
*/
export const getDateInStandardYMDFormat = (date: string): string => standardYMDFormatDate(new Date(Date.parse(date)));

/**
 * get a date as string in standard format
 * @author Kevin Parkinson
 * @param {string} date - the date  you wish  to format
 * @param {string} language - the i18n language
*/
export const getDateAsStringInStandardFormat = (date: Date, language: string): string => standardFormatDate(date, getContentfulLanguageFromi18nLanguage(language));

/**
 * get a date as string in standard format
 * @author Kevin Parkinson
 * @param {string} date - the date  you wish  to format
*/
export const getDateAndTimeInUTCFormat = (date: string): string => new Date(date).toUTCString();

/**
 * get today's date as string in standard format
 * @author Kevin Parkinson
 * @param {string} language - the i18n language
*/
export const getTodayInStandardFormat = (language: string): string => standardFormatDate(new Date(), getContentfulLanguageFromi18nLanguage(language));

/**
 * TODO: describe function
 * @author Kevin Parkinson
 * @param {Date} the date to convert to short yyyy-mm-dd
*/
export const toShortDate = (d: Date) => format(d, 'yyyy-MM-dd');

/**
 * Gets the day 7 days ago
 * @author Kevin Parkinson
*/
export const getDate7DaysAgo = () => subDays(new Date(), 7);

/**
 * Gets the day 15 days ago
 * @author Kevin Parkinson
*/
export const getDate15DaysAgo = () => subDays(new Date(), 15);

/**
 * Gets the day 30 days ago
 * @author Kevin Parkinson
*/
export const getDate30DaysAgo = () => subDays(new Date(), 30);
