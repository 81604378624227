import CloudSearchResults, { ArticleCloudSearchResult, PlanogramCloudSearchResult } from 'src/types/cloudSearchResults';

const getArticleTitleInLanguage = (item: any, language: string) => {
  if (language.toLowerCase() === 'en') {
    if (item.title) return item.title;
    return '';
  }
  if (item.title_fr) return item.title_fr;
  if (!item.title_fr && item.title) return item.title;
  return '';
};

const getPlanogramTitleInLanguage = (item: any, language: string) => {
  if (language.toLowerCase() === 'en') {
    if (item.title) return item.title;
    return '';
  }
  if (item.title) return item.title_fr;
  if (!item.title_fr && item.title) return item.title;
  return '';
};

export const toArticleCloudSearchResult = (item: any, language: string): ArticleCloudSearchResult => ({
  id: item.id,
  title: getArticleTitleInLanguage(item, language),
  summary: '',
  specificBu: item.business_unit ? item.business_unit : '',
  category: item.category ? item.category : '',
  subcategory: item.sub_category ? item.sub_category : '',
  url: item.url ? item.url : '',
  brands: item.brands ? item.brands : [],
  regions: item.regions ? item.regions : [],
  classOfTrades: item.class_of_trade ? item.class_of_trade : []
});

export const toPlanogramCloudSearchResult = (item: any, language: string): PlanogramCloudSearchResult => ({
  id: item.id,
  title: getPlanogramTitleInLanguage(item, language),
  category: item.category ? item.category : '',
  url: item.attachment ? item.attachment : '',
  shelfSize: item.shelf_size ? item.shelf_size : '',
  brands: item.brands ? item.brands : [],
  regions: item.regions ? item.regions : [],
  classOfTrades: item.class_of_trade ? item.class_of_trade : []
});

export const toArticleCloudSearchResults = (data: any[], language: string): ArticleCloudSearchResult[] => data.map((item) => toArticleCloudSearchResult(item, language));
export const toPlanogramCloudSearchResults = (data: any[], language: string): PlanogramCloudSearchResult[] => data.map((item) => toPlanogramCloudSearchResult(item, language));

/**
 * TODO: describe function
 * @author Kevin Parkinson
 * @param regions (any[])
*/
export const toCloudSearchResults = (data: any, language: string): CloudSearchResults => {
  const results: CloudSearchResults = { articles: [], planograms: [] };
  results.articles = toArticleCloudSearchResults(data.results_articles, language);
  results.planograms = toPlanogramCloudSearchResults(data.results_planograms, language);
  return results;
};
