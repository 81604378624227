// Contentful
import StationPortalGeneralListGroupsCollection from 'src/types/contentful/stationPortalGeneralListGroupsCollection';
import { GeneralLink } from 'src/types/contentful/generalLinkListCollection';

// Custom types
import Link from 'src/types/link';

/**
 * Converts a list of external links in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {StationPortalGeneralListGroupsCollection} divisions - the Contentful external links to convert
 * @return {Link[]}
*/
export const toExternalLinks = (links: StationPortalGeneralListGroupsCollection): Link[] => {
  if (links) {
    return links.items[0].linkedFrom.generalLinkListCollection.items.map((it) => ({
      linkTitle: it.linkTitle ?? '',
      linkUrl: it.linkUrl ?? '',
      logo: it.logo && it.logo.url ? it.logo.url : ''
    }));
  }
  return [];
};

/**
 * Converts a list of links in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {GeneralLink[]} links - the Contentful links (with brand, region and class of trade filters) to convert
 * @return {Link[]}
*/
export const toLinksWithFilters = (links: GeneralLink[]): Link[] => {
  if (links) {
    return links.map((l) => ({
      linkTitle: l.linkTitle ?? '',
      linkUrl: l.linkUrl ?? '',
      logo: l.logo && l.logo.url ? l.logo.url : ''
    }));
  }
  return [];
};

/**
 * Converts a list of links in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {any} links - the Contentful links to convert
 * @return {Link[]}
*/
export const toLinks = (links: any): Link[] => {
  if (links) {
    return links.items.map((l) => ({
      linkTitle: l.linkTitle ?? '',
      linkUrl: l.linkUrl ?? '',
      logo: l.logo && l.logo.url ? l.logo.url : ''
    }));
  }
  return [];
};
