/**
 * TODO: describe function
 * @author Kevin Parkinson
 * @param TODO: fill out params
 * @comments TODO: fill out comment
*/
export const introspectionQueryResultData = {
  __schema: {
    types: [
      {
        kind: 'INTERFACE',
        name: 'Entry',
        possibleTypes: [
          {
            name: 'Article'
          },
          {
            name: 'CriticalUpdate'
          },
          {
            name: 'Announcement'
          },
          {
            name: 'LatestUpdate'
          },
        ]
      }
    ]
  }
};
