// Custom types
import GetRequestOptions from 'src/types/gateway/requestReponse/getRequest';
import GetRequestOptionsWithParams from 'src/types/gateway/requestReponse/getRequestWithParams';
import PostRequestOptions from 'src/types/gateway/requestReponse/postRequest';

// Logging
import logger from 'src/logging/logger';

// Services
import { gatewayService } from 'src/services/gatewayService';

/**
 * A standard rejected promise handler which logs to AWS and calls reject
 * @author Kevin Parkinson
 * @param {string} serviceName - The name of the service where the error happened
 * @param {string} reject - The reject mwethod to reject the promise
 * @param {any} err - The error object that happened
*/
export const standardRejectedPromise = (serviceName: string, reject, err: any) => {
  logger.error(`${serviceName}: ${err}`);
  gatewayService.logErrorActivity(err);
  reject(new Error(`Internal server error: ${err.message}`));
};

/**
 * A standard rejected promise handler which logs to AWS and calls reject
 * @author Kevin Parkinson
 * @param {string} reject - The OKTA ID of the user you wish to fetch
 * @param {any} err - The OKTA ID of the user you wish to fetch
*/
export const standardGatewayRejectedPromise = (reject, err: any) => {
  logger.error(`Gateway Service: ${err}`);
  gatewayService.logErrorActivity(err);
  reject(new Error(`Internal server error: ${err.message}`));
};

/**
 * A standard log message handler which logs to the console using info
 * @author Kevin Parkinson
 * @param {string} serviceName - The name of the service where the error happened
 * @param {any} err - The error object that happened
 * @param {string} info - Extra info about the error
*/
export const logHarmlessErrorAsinfo = (serviceName: string, err: any, info: string) => logger.verbose(`${serviceName}: ${err}. ${info}`);

/**
 * A standard way to log errors from Contentful
 * @author Kevin Parkinson
 * @param {string} serviceName - the source file/service/page of the error
 * @param {string} err - the error message
 * @param {string} errMetaData - the error stack trace
*/
export const contentfulError = (serviceName: string, err: string, errMetaData?: any) => {
  const errorString = `${serviceName}: ${err} | stack trace: ${errMetaData}`;
  gatewayService.logErrorActivity(errorString);
  logger.error(errorString);
};

/**
 * The method is named for what it does
 * @author Kevin Parkinson
 * @param {string} serviceName - the source file/service/page of the error
 * @param {string} responseData - t he graphQL response
*/
export const logContentfulGraphQLErrorsIfAny = (serviceName: string, responseData: any) => {
  if (responseData.errors) {
    gatewayService.logErrorActivity(`GraphQL error in ${serviceName}: : ${responseData.errors}`);
    logger.error([`GraphQL error in ${serviceName}`, responseData.errors]);
  }
};

/**
 * A standard formatter of request urls for the gateway api
 * @author Kevin Parkinson
 * @param {string} baseAPI - the name of the main resource of the request
 * @param {string[]} subPaths? - an array of string to append to the url in order
 * @format /user/subPath1/subPath2/subPath3
*/
export const getAPIPath = (baseAPI: string, subPaths?: string[]) => {
  if (!baseAPI) return '';
  let apiPath = `/${baseAPI}`;
  if (subPaths) {
    for (let i = 0; i < subPaths.length; i++) {
      apiPath += `/${subPaths[i]}`;
    }
  }
  return apiPath;
};

/**
 * Gets a request for making GET requests to the Gateway API with no auth
 * @author Kevin Parkinson
 * @param { headers: any, response: boolean, queryStringParameters: any } options
*/
export const gatewayGetRequest = (): GetRequestOptions => ({ headers: {}, response: false, queryStringParameters: {} });

/**
 * Gets a request for making GET requests to the Gateway API with auth token
 * @author Kevin Parkinson
 * @param {string} authToken
*/
export const gatewayGetRequestWithAuth = (authToken: string): GetRequestOptions => ({
  headers: {
    Accept: '/',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`
  },
  response: false,
  queryStringParameters: {}
});

/**
 * Gets a request for making GET requests to the Gateway API with auth token and params
 * @author Kevin Parkinson
 * @param {string} authToken
 * @param {any} queryStringParams
*/
export const gatewayGetRequestWithQueryParams = (authToken: string, queryStringParams: any): GetRequestOptions => ({
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`
  },
  response: false,
  queryStringParameters: queryStringParams
});

/**
 * Gets a request for making GET requests to the Gateway API with auth token
 * @author Kevin Parkinson
 * @param {string} authToken
 * @param {any} queryStringParams
*/
export const gatewayGetRequestWithAuthAndParams = (authToken: string, queryStringParams: any): GetRequestOptionsWithParams => ({
  headers: {
    Accept: '/',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`
  },
  params: {},
  response: false,
  queryStringParameters: queryStringParams
});

/**
 * Gets a request for making GET requests to the Gateway API with auth token
 * @author Kevin Parkinson
 * @param {string} authToken
 * @param {any} queryStringParams
*/
export const gatewayGetRequestWithParams = (queryStringParams: any): GetRequestOptionsWithParams => ({
  headers: {
    Accept: '/',
    'Content-Type': 'application/json'
  },
  params: {},
  response: false,
  queryStringParameters: queryStringParams
});

/**
 * Gets a request for making POST/PUT/DELETE requests to the Gateway API with no auth
 * @author Kevin Parkinson
 * @param body: any, headers: any } options
*/
export const gatewayUpdateRequest = (requestBody): PostRequestOptions => ({
  body: requestBody,
  headers: {
    Accept: '/',
    'Content-Type': 'application/json'
  }
});

/**
 * Gets a request for making POST/PUT/DELETE requests to the Gateway API with auth token
 * @author Kevin Parkinson
 * @param body: any, headers: any } options
 * @param {string} authToken
*/
export const gatewayUpdateRequestWithAuth = (requestBody, authToken: string): PostRequestOptions => ({
  body: requestBody,
  headers: {
    Accept: '/',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`
  }
});

/**
 * Gets a request for making POST/PUT/DELETE requests to the Gateway API with auth token
 * @author Kevin Parkinson
 * @param {string} authToken
*/
export const gatewayDeleteRequestWithAuth = (authToken: string): PostRequestOptions => ({
  body: null,
  headers: {
    Accept: '/',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`
  }
});
