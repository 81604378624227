import { Component } from 'react';
import ServerError from 'src/pages/ServerError';

interface MainErrorBoundaryState {
  hasError: boolean;
}

class MainErrorBoundary extends Component<{}, MainErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <ServerError />;
    }
    return children;
  }
}

export default MainErrorBoundary;
