// REDUX
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

// Custom types
import type { AppThunk } from 'src/store';

// Hooks and utils
import logger from 'src/logging/logger';

export interface StationsState {
  brands: string[];
  classOfTrade: string[];
  regions: string[];
}

const initialState: StationsState = {
  brands: [],
  classOfTrade: [],
  regions: []
};
const className = '[Contentful Lookups Store]';

const slice = createSlice({
  name: 'stations',
  initialState,
  reducers: {
    setBrands(
      state: StationsState,
      action: PayloadAction<string[]>
    ): void {
      state.brands = action.payload;
    },
    setClassofTrades(
      state: StationsState,
      action: PayloadAction<string[]>
    ): void {
      state.classOfTrade = action.payload;
    },
    setRegions(
      state: StationsState,
      action: PayloadAction<string[]>
    ): void {
      state.regions = action.payload;
    },
  }
});

export const { reducer } = slice;

/**
 * Saves a list of brands in cache
 * @author Kevin Parkinson
 * @param {string[]} brands - the list of brands
*/
export const saveBrands = (brands: string[]): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(slice.actions.setBrands(brands));
  } catch (error) {
    logger.error(className, error);
  }
};

/**
 * Saves a list of classOfTrades in cache
 * @author Kevin Parkinson
 * @param {string[]} classOfTrades - the list of classOfTrades
*/
export const saveClassOfTrades = (classOfTrades: string[]): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(slice.actions.setClassofTrades(classOfTrades));
  } catch (error) {
    logger.error(className, error);
  }
};

/**
 * Saves a list of regions in cache
 * @author Kevin Parkinson
 * @param {string[]} regions - the list of regions
*/
export const saveRegions = (regions: string[]): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(slice.actions.setRegions(regions));
  } catch (error) {
    logger.error(className, error);
  }
};

export default slice;
