import 'react-app-polyfill/ie11';
import { isIE } from 'react-device-detect';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import App from './App';
import { AuthProvider } from './contexts/AmplifyContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { PersistGate } from 'redux-persist/integration/react';
import MainErrorBoundary from './components/MainErrorBoundary';
import store, { persistor } from './store';

if (isIE) {
  ReactDOM.render(<div>IE is not suppoerted.</div>, document.getElementById('root'));
} else {
  ReactDOM.render(
    <StrictMode>
      <HelmetProvider>
        <MainErrorBoundary>
          <ReduxProvider store={store}>
            <PersistGate
              loading={null}
              persistor={persistor}
            >
              <StyledEngineProvider injectFirst>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <SettingsProvider>
                    <BrowserRouter>
                      <AuthProvider>
                        <App />
                      </AuthProvider>
                    </BrowserRouter>
                  </SettingsProvider>
                </LocalizationProvider>
              </StyledEngineProvider>
            </PersistGate>
          </ReduxProvider>
        </MainErrorBoundary>
      </HelmetProvider>
    </StrictMode>,
    document.getElementById('root')
  );
}
