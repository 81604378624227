// React
import type { FC } from 'react';

// Material UI
import { Link } from '@material-ui/core';

// Custom types
import ImageLinkInNewWindowProps from 'src/types/props/imageLinkInNewWindowProps';

const ImageLinkInNewWindow: FC<ImageLinkInNewWindowProps> = ({ url, imgSrc, imgAlt }: ImageLinkInNewWindowProps) => (
  <Link
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    style={{ textDecoration: 'none' }}
  >
    <img
      src={imgSrc}
      alt={imgAlt}
      style={{ paddingLeft: '10px' }}
    />
  </Link>
);

export default ImageLinkInNewWindow;
