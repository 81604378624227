// React
import {
  useRef,
  useState,
  useEffect
} from 'react';
import type { FC, MouseEvent } from 'react';
import toast from 'react-hot-toast';
import { QueryClient, useQuery } from 'react-query';

// Material UI
import {
  // Autocomplete,
  Box,
  Button,
  ButtonBase,
  Card,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

// Custom components and types
import { Station, User } from 'src/types/gateway';
import GatewayStationSummary, { getStationLabel } from 'src/types/gateway/gatewayStationSummary';
import {
  allAssignedStations,
  userHasStationsAssigned,
  isAdminUser,
  isAdminOrTMUser
} from 'src/types/gateway/gatewayUser';

// Services
import { gatewayService } from 'src/services/gatewayService';
import { contentfulService } from 'src/services/contentfulService';

// Store
import type { RootState } from 'src/store';
import { useSelector, useDispatch } from 'src/store';
import {
  setSelectedStation,
  setSelectedBrand,
  setUpdatedDate,
  setShowContent,
  setApplyFilters
} from 'src/slices/userDetails';

// Hools and Utils
import { appConfig } from 'src/config/config';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import logger from 'src/logging/logger';
import { getEmptyStation } from 'src/types/gateway/gatewayStation';
import { getEmptyBrand } from 'src/types/brand';
import { getStationsStaleTime } from 'src/utils/cachingUtils';
import { getStationsQueryFn } from 'src/data/reactQueryFunctions';
import { getLogMsg } from 'src/utils/loggingUtils';
import { shouldIApplyFilters } from 'src/utils/contentFilterUtils';
import { updateUserActivityDateTime } from 'src/utils/cookieUtils';

// Styles
const useStyles = makeStyles({
  paper: {
    position: 'absolute',
    width: 550,
    minHeight: 350
  }
});

// Constants
const getModalStyle = () => {
  const pos = 50;
  return {
    top: '200px',
    left: `${pos}%`,
    transform: `translate(-${pos}%, -${pos}%)`,
  };
};

const StationPickerPopup: FC = () => {
  // State variables
  const [filterByStation, setFilterByStation] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [isManuallyRefreshing, setIsManuallyRefreshing] = useState<boolean>(false);
  const [modalStyle] = useState(getModalStyle);
  const [stations, setStations] = useState<GatewayStationSummary[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState<null | HTMLElement>(null);
  const [noStationForFilter, setNoStationsForFilter] = useState<boolean>(false);

  // Store
  const gatewayUser = useSelector((state: RootState): User | null => (state && state.userDetails && state.userDetails.gatewayUserDetails ? state.userDetails.gatewayUserDetails : null));
  const selectedStation = useSelector((state: RootState): Station | null => (state && state.userDetails && state.userDetails.selectedStation ? state.userDetails.selectedStation : getEmptyStation()));
  const brands = useSelector((state: RootState): string[] => state.contentfulLookups.brands);
  const regions = useSelector((state: RootState): string[] => state.contentfulLookups.regions);
  const classOfTrades = useSelector((state: RootState): string[] => state.contentfulLookups.classOfTrade);
  const dispatch = useDispatch();

  // Hooks and Utils
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { t: translate, i18n } = useTranslation();

  // Constants
  const classes = useStyles();
  const componentName = 'Station Picker';
  const {
    selectStation,
    stationPicker,
    stationPickerOptions
  } = appConfig.i18n.formFields;
  const {
    youCanNowSeeAllContent,
    stationsRefreshed,
    couldNotFindStationsbasedOnSearch,
    loadingRefreshingStations
  } = appConfig.i18n.messages;
  const {
    refreshStations,
    returnToDefaultView
  } = appConfig.i18n.actions;
  const { gatewayStations } = appConfig.caching;
  const queryClient = new QueryClient();

  // React Query/Cache
  const {
    isLoading: areStationsLoading,
    error: stationErrors,
    data: stationData,
    isFetching: fetchingStations
  } = useQuery(gatewayStations, getStationsQueryFn(), { staleTime: getStationsStaleTime() });

  const getStations = async () => {
    try {
      let stationList;
      if (stationData && isAdminUser(gatewayUser)) stationList = stationData;
      else stationList = _.uniqBy(allAssignedStations(gatewayUser), (s: GatewayStationSummary) => s.site_id); // for TM or regular users, concat mdm stations and admin assigned stations and make sure there are no duplicates
      if (stationList) {
        stationList = _.orderBy(stationList, ['site_id'], ['asc']);
        setStations(stationList);
      }
    } catch (err) {
      gatewayService.logErrorActivity(err);
      logger.error(getLogMsg(`${componentName} - getStations`, err));
      toast.error(`${translate(appConfig.i18n.messages.couldNotGetStations)}: ${err}`);
    }
  };

  useEffect(() => {
    getStations();
  }, [stationData]);

  // Event handlers
  const handleOpen = (): void => {
    setOpen(true);
    getStations();
    // record user activity
    updateUserActivityDateTime();
  };

  const handleClose = (): void => {
    setFilterByStation('');
    setNoStationsForFilter(false);
    setOpen(false);
  };

  const handleStationChange = async (stationID: string) => {
    const station = await gatewayService.getStation(stationID);
    dispatch(setSelectedStation(station));
    if (station) {
      const brand = await contentfulService.getBrand(station.brand, i18n.language);
      dispatch(setSelectedBrand(brand));
    }

    const applyFilters = shouldIApplyFilters(station, brands, classOfTrades, regions);
    dispatch(setApplyFilters(applyFilters));
    dispatch(setShowContent(applyFilters));

    setFilterByStation('');
    setNoStationsForFilter(false);
    setOpen(false);
    toast.success(translate(appConfig.i18n.messages.stationChanged).toString());
    dispatch(setUpdatedDate());
    // ideally, it would be great not to refresh the page, but Redux state management is complex and
    // state is not updating in time
    // so, refresh the current page the users is on
    setTimeout(() => { window.location.href = window.location.pathname + window.location.search; }, 1000);
    // record user activity
    updateUserActivityDateTime();
  };

  const handleFilterChange = (event): void => {
    setFilterByStation(event.target.value);
    // whenever the user applies the filter, set the current page to the first
    setPage(0);
    const filteredStations = stations.filter((st) => filterByStation === '' || getStationLabel(st).toLowerCase().includes(filterByStation.toLowerCase()));
    setNoStationsForFilter(filteredStations.length === 0);
    // record user activity
    updateUserActivityDateTime();
  };

  const handleChangePage = async (event: unknown, newPage: number) => setPage(newPage);

  const handleRefreshData = async () => {
    try {
      setIsManuallyRefreshing(true);
      const refreshedStationData = await queryClient.fetchQuery(gatewayStations, getStationsQueryFn(), { staleTime: getStationsStaleTime() });
      setStations(_.orderBy(refreshedStationData, ['site_id'], ['asc']));
      setFilterByStation('');
      setNoStationsForFilter(false);
      toast.success(translate(stationsRefreshed).toString());
    } catch (err) {
      logger.error(getLogMsg(`${componentName} - handleRefreshData`, err));
      toast.error(`${translate(appConfig.i18n.messages.couldNotGetStations)}: ${err}`);
    } finally {
      setOptionsAnchorEl(null);
      setIsManuallyRefreshing(false);
    }
    // record user activity
    updateUserActivityDateTime();
  };

  const handleReturnToDefaultView = async () => {
    try {
      dispatch(setSelectedStation(getEmptyStation()));
      dispatch(setSelectedBrand(getEmptyBrand()));
      dispatch(setApplyFilters(false));
      dispatch(setShowContent(true));
      setOptionsAnchorEl(null);
      setOpen(false);
      setFilterByStation('');
      setNoStationsForFilter(false);
      toast.success(translate(youCanNowSeeAllContent).toString());
      dispatch(setUpdatedDate());
      // record user activity
      updateUserActivityDateTime();
      // ideally, it would be great not to refresh the page, but Redux state management is complex and
      // state is not updating in time
      // so, refresh the current page the users is on
      setTimeout(() => { window.location.href = window.location.pathname + window.location.search; }, 1000);
    } catch (err) {
      logger.error(getLogMsg(`${componentName} - handleReturnToDefaultView`, err));
      toast.error(`Could not refresh to admin view: ${err}`);
    } finally {
      setOptionsAnchorEl(null);
    }
  };

  const handleOptionsClick = (event: MouseEvent<HTMLButtonElement>) => {
    setOptionsAnchorEl(event.currentTarget);
  };

  const handleOptionsClose = () => {
    setOptionsAnchorEl(null);
  };

  const isReturnToDefaultDisabled = () => {
    if (!selectedStation) return true;
    if (!selectedStation.brand) return true;
    return false;
  };

  const isStationAlreadySelected = (siteID: string) => {
    if (!selectedStation) return false;
    if (!selectedStation.site_id) return false;
    if (selectedStation.site_id === siteID) return true;
    return false;
  };

  return (
    <>
      <Tooltip title={translate(stationPicker)}>
        <Box
          component={ButtonBase}
          onClick={handleOpen}
          ref={anchorRef}
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <LocalGasStationIcon color="primary" />
        </Box>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div
          style={modalStyle}
          className={classes.paper}
        >
          <Card
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              left: 0,
              p: 1,
              position: 'fixed',
              top: 0,
              width: '100%',
              zIndex: 2000,
              backgroundColor: 'background.paper'
            }}
          >
            <CardHeader
              title={(
                <Typography
                  color="GrayText"
                  variant="overline"
                  fontWeight="700"
                  fontSize="large"
                >
                  {translate(stationPicker)}
                </Typography>
              )}
            />
            {(areStationsLoading || fetchingStations || isManuallyRefreshing)
            && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    px: 2,
                    py: 2
                  }}
                >
                  <Typography
                    color="GrayText"
                    variant="overline"
                  >
                    {translate(loadingRefreshingStations)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    px: 2,
                    py: 2
                  }}
                >
                  <CircularProgress color="primary" />
                </Box>
              </>
            )}
            {!areStationsLoading && !fetchingStations && !isManuallyRefreshing && stationErrors
            && (
              <Box sx={{ p: 2 }}>
                <Typography
                  color="red"
                  variant="caption"
                >
                  {stationErrors}
                </Typography>
              </Box>
            )}
            {!areStationsLoading && !fetchingStations && !isManuallyRefreshing
            && (
              <Grid container>
                {(gatewayUser.is_admin || gatewayUser.is_tm || gatewayUser.is_super)
                && (
                  <Grid
                    item
                    textAlign="right"
                    xs={12}
                    sx={{
                      px: 1,
                      py: 1
                    }}
                  >
                    <Button
                      aria-controls="station-options-menu"
                      aria-haspopup="true"
                      variant="outlined"
                      onClick={handleOptionsClick}
                      sx={{ px: 1 }}
                    >
                      {translate(stationPickerOptions)}
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={optionsAnchorEl}
                      dir="left"
                      keepMounted
                      open={Boolean(optionsAnchorEl)}
                      onClose={handleOptionsClose}
                    >
                      <MenuItem onClick={handleRefreshData}>{translate(refreshStations)}</MenuItem>
                      <MenuItem
                        disabled={isReturnToDefaultDisabled()}
                        onClick={handleReturnToDefaultView}
                      >
                        {translate(returnToDefaultView)}
                      </MenuItem>
                    </Menu>
                  </Grid>
                )}
                {(isAdminOrTMUser(gatewayUser) || userHasStationsAssigned(gatewayUser))
                && (
                  <Grid
                    item
                    xs={12}
                    sx={{ px: 1 }}
                  >
                    <TextField
                      fullWidth
                      label="Filter"
                      variant="outlined"
                      style={{ fontSize: 'small' }}
                      size="small"
                      onChange={handleFilterChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                )}
                {noStationForFilter && (gatewayUser.is_admin || gatewayUser.is_tm || gatewayUser.is_super)
                && (
                  <Box sx={{ p: 2 }}>
                    <Typography
                      color="GrayText"
                      variant="caption"
                    >
                      {translate(couldNotFindStationsbasedOnSearch)}
                    </Typography>
                  </Box>
                )}
                <Grid
                  item
                  xs={12}
                  sx={{ px: 1, py: 1 }}
                >
                  <TableContainer style={{ width: '100%' }}>
                    <Table
                      stickyHeader
                      style={{ width: '100%' }}
                    >
                      <TableBody style={{ width: '100%' }}>
                        {stations
                        && stations
                          .filter((st) => filterByStation === '' || getStationLabel(st).toLowerCase().includes(filterByStation.toLowerCase()))
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((station) => (
                            <TableRow key={station.site_id}>
                              <TableCell>
                                <Typography
                                  color="GrayText"
                                  variant="caption"
                                  sx={{
                                    pr: 2
                                  }}
                                >
                                  {getStationLabel(station)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Button
                                  color="primary"
                                  variant="outlined"
                                  disabled={isStationAlreadySelected(station.site_id)}
                                  onClick={() => handleStationChange(station.site_id)}
                                  sx={{
                                    height: '20px',
                                    ml: 1
                                  }}
                                >
                                  {translate(selectStation)}
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={stations.filter((st) => filterByStation === '' || getStationLabel(st).toLowerCase().includes(filterByStation.toLowerCase())).length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </Grid>
              </Grid>
            )}
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default StationPickerPopup;
