import { EntryCollection } from 'contentful';
import ContentfulCategory from 'src/types/contentful/contentfulCategory';

/**
 * Converts a list of categories in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {EntryCollection<ContentfulCategory>} categories - the Contentful categories to convert
 * @return {string[]}
*/
export const simplifyContentfulCategories = (categories: EntryCollection<ContentfulCategory>): string[] => categories.items.map((item) => (item && item.fields && item.fields.category ? item.fields.category : '')).sort();
