// React
import { createContext, useEffect, useReducer } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';

// Amplify
import Amplify, { Auth } from 'aws-amplify';

// Services
import { gatewayService } from 'src/services/gatewayService';

// Custom types
import AuthProviderProps from 'src/types/props/authGaurdProps';
import {
  State,
  AuthContextValue,
  InitializeAction,
  LoginAction,
  Action
} from 'src/types/amplify/authTypes';

// Hooks and utils
import { amplifyConfig } from 'src/config/config';
import logger from 'src/logging/logger';
import { getVerboseLogMsg } from 'src/utils/loggingUtils';
import UserActivityType, { activityTypes } from 'src/types/gateway/userActivityType';
import {
  isUserLoggedIn,
  setUserLoggedIn,
  setUserLoggedOut
} from 'src/utils/cookieUtils';

Amplify.configure(amplifyConfig);

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;
    if (isAuthenticated && !isUserLoggedIn()) {
      gatewayService.logActivity(activityTypes.login as UserActivityType);
      setUserLoggedIn();
    }
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'Amplify',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user
          }
        });
      } catch (error) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    await Auth.federatedSignIn();
    const user = await Auth.signIn(email, password);
    if (user.challengeName) {
      const err = `Unable to login, because challenge '${user.challengeName}' is mandated and we did not handle this case.`;
      gatewayService.logErrorActivity(err);
      logger.error(err);
      return;
    }
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const logout = async (): Promise<void> => {
    setUserLoggedOut();
    const session = await Auth.currentSession();
    const { email } = session.getIdToken().payload;
    gatewayService.logActivityWithExplicitEmail(email, activityTypes.logout as UserActivityType);
    await Auth.signOut();
    logger.verbose(getVerboseLogMsg('AmplifyContext', 'logout', 'Auth.signOut called'));
    logger.verbose(getVerboseLogMsg('AmplifyContext', 'logout', 'dispatching logout'));
    dispatch({
      type: 'LOGOUT'
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Amplify',
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
