// Contentful
import { Entry } from 'contentful';

// Custom types
import { CPromoPeriodArticle } from 'src/types/contentful';
import BigCalendarEvent from 'src/types/bigCalendarEvent';
import { GeneralCalendarEntry } from 'src/types/contentful/generalCalendarEntryCollection';

/**
 * Converts an 'article summary' in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {GeneralCalendarEntry} entry - the Contentful article summary to convert
 * @return {ArticleSummary}
*/

function parseDate(input) {
  const parts = input.match(/(\d+)/g);
  // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
  return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
}

export const articleToCalendarEventWithFilters = (entry: GeneralCalendarEntry, index: number): BigCalendarEvent => {
  const { startDate, endDate, entryTitle, article, colour } = entry;
  const sD = parseDate(startDate);
  const endDay = parseDate(endDate);
  const currentEndDate = endDay.getDate();
  const newEndDate = endDay.setDate(currentEndDate + 1);
  const eD = new Date(newEndDate);

  const calendarEvent: BigCalendarEvent = {
    id: index,
    start: sD,
    end: eD,
    allDay: true,
    title: entryTitle ?? '',
    colour,
    internalId: article.sys.id
  };
  return calendarEvent;
};

/**
 * Converts an 'article summary' in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {GeneralCalendarEntry[]} entries - the Contentful articles to convert
 * @return {ArticleSummary}
*/
export const articlesToCalendarEventsWithFilters = (entries: GeneralCalendarEntry[]): BigCalendarEvent[] => entries.map((e, idx) => articleToCalendarEventWithFilters(e, idx));

/**
 * Converts an 'article summary' in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {Entry<ContentfulArticleSummary>} entry - the Contentful article summary to convert
 * @return {ArticleSummary}
*/

export const articleToCalendarEvent = (entry: Entry<CPromoPeriodArticle>, index: number): BigCalendarEvent => {
  const { fields } = entry;
  const startDate = parseDate(fields.startDate);
  const endDate = parseDate(fields.endDate);
  const currentEndDate = endDate.getDate();
  const newEndDate = endDate.setDate(currentEndDate + 1);
  const eD = new Date(newEndDate);
  const calendarEvent: BigCalendarEvent = {
    id: index,
    start: startDate,
    end: eD,
    allDay: true,
    title: fields.entryTitle ?? '',
    colour: fields.colour,
    internalId: fields.article.sys.id
  };
  return calendarEvent;
};

/**
 * Converts an 'article summary' in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {Entry<ContentfulPromotionalPeriodArticle[]>[]} entries - the Contentful articles to convert
 * @return {ArticleSummary}
*/
export const articlesToCalendarEvents = (entries: Entry<CPromoPeriodArticle>[]): BigCalendarEvent[] => entries.map((e, idx) => articleToCalendarEvent(e, idx));
