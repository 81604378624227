// React
import type { FC } from 'react';
import PropTypes from 'prop-types';

// Material UI
import {
  Box,
  Typography
} from '@material-ui/core';

// Hooks, Config and utils
import { useTranslation } from 'react-i18next';

const ErrorMessage: FC<{ errorMessageKey: string }> = ({ errorMessageKey }) => {
  const { t: translate } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        height: '100%',
        justifyContent: 'space-between',
        verticalAlign: 'top',
        px: 2,
        py: 2
      }}
    >
      <Typography
        color="red"
        variant="caption"
      >
        {translate(errorMessageKey)}
      </Typography>
    </Box>
  );
};

ErrorMessage.propTypes = { errorMessageKey: PropTypes.string.isRequired };

export default ErrorMessage;
