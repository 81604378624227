import { appConfig } from 'src/config/config';
import friendlyUrl from 'friendly-url';

const { articleWithQS, article } = appConfig.routes;
const idKey = '{id}';

/**
 * TODO: describe function
 * @author Kevin Parkinson
 * @param TODO: fill out params
*/
export function getArticleRoute(articleId: string) {
  // format: https://thegateway.ca/article?id=nnn
  return `${process.env.PUBLIC_URL}/${articleWithQS.replace(idKey, articleId)}`;
}

/**
 * TODO: describe function
 * @author Kevin Parkinson
 * @param TODO: fill out params
*/
export function getFriendlyArticleUrl(articleName: string) {
  // https://thegateway.ca/article/awesome-important-article
  return `${article}/${friendlyUrl(articleName)}`;
}
