import GatewayUser, { getEmptyUser } from 'src/types/gateway/gatewayUser';
import GatewayStation, { getEmptyStation } from 'src/types/gateway/gatewayStation';
import Brand, { getEmptyBrand } from 'src/types/brand';
import UninitialisedUserType from 'src/types/uninitialisedUserType';

export type LatestUpdateRangeType = '7 Days' | '15 Days' | '30 Days' | 'Custom';

export const initialState: UserDetailsState = {
  updatedDateTime: '',
  showContent: true,
  applyFilters: false,
  uninitialisedUserType: 'null',
  gatewayUserDetails: getEmptyUser(),
  selectedBrand: getEmptyBrand(),
  selectedStation: getEmptyStation()
};

interface UserDetailsState {
  updatedDateTime: string;
  showContent: boolean;
  applyFilters: boolean;
  uninitialisedUserType: UninitialisedUserType;
  gatewayUserDetails: GatewayUser;
  selectedBrand: Brand;
  selectedStation: GatewayStation;
}

export default UserDetailsState;
