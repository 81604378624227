import MysteryShopperReportItem from 'src/types/mysteryShopperReportItem';
import { nonApplicable } from 'src/components/dashboard/mysteryshopper/mysteryShopperDashboard';

const convertYesNoNA = (value: string) => {
  let conversation;
  switch (value) {
    case 'YES':
      conversation = '100';
      break;
    case 'NO':
      conversation = '0';
      break;
    case 'NA':
    default:
      conversation = value;
  }
  return conversation;
};

const getScorePercentage = (value: any) => (value ? value.percentage : nonApplicable);

export const toMysteryShopReportItem = (mysteryShop: any): MysteryShopperReportItem => ({
  bu: mysteryShop.ID,
  documentDate: mysteryShop.DATE,
  city: mysteryShop.city,
  province: mysteryShop.province,
  fuelBrand: mysteryShop.fuel_brand,
  operatingModel: mysteryShop.operating_model,
  rom: mysteryShop.rom,
  tm: mysteryShop.tm,
  shopType: 'MS',
  overallScore: mysteryShop.overall_score, // getScorePercentage(mysteryShop.overall_score),
  interiorScore: getScorePercentage(mysteryShop.interior_score),
  exteriorScore: getScorePercentage(mysteryShop.exterior_score),
  washroomScore: getScorePercentage(mysteryShop.washroom_score),
  customerServiceScore: getScorePercentage(mysteryShop.customer_service_score),
  loyaltyAsk: convertYesNoNA(mysteryShop.loyalty_ask),
  loyaltyOffer: convertYesNoNA(mysteryShop.loyalty_offer),
  upsellProductService: convertYesNoNA(mysteryShop.upsell_product)
});

/**
 * TODO: describe function
 * @author Kevin Parkinson
 * @param {any[]} mysteryShops
*/
export const toMysteryShopReportItems = (mysteryShops: any[]): MysteryShopperReportItem[] => mysteryShops.map((r) => toMysteryShopReportItem(r));

export const toAgeRestrictedShopReportItem = (ageRestrictedShop: any): MysteryShopperReportItem => ({
  bu: ageRestrictedShop.ID,
  documentDate: ageRestrictedShop.DATE,
  city: ageRestrictedShop.city,
  province: ageRestrictedShop.province,
  fuelBrand: ageRestrictedShop.fuel_brand,
  operatingModel: ageRestrictedShop.operating_model,
  rom: ageRestrictedShop.rom,
  tm: ageRestrictedShop.tm,
  shopType: 'AR',
  overallScore: ageRestrictedShop.is_id_checked ? 100 : 0,
  interiorScore: '',
  exteriorScore: '',
  washroomScore: '',
  customerServiceScore: '',
  loyaltyAsk: '',
  loyaltyOffer: '',
  upsellProductService: ''
});

/**
 * TODO: describe function
 * @author Kevin Parkinson
 * @param {any[]} ageRestrictedShops
*/
export const ageRestrictedToMysteryShopReportItems = (ageRestrictedShops: any[]): MysteryShopperReportItem[] => ageRestrictedShops.map((r) => toAgeRestrictedShopReportItem(r));
