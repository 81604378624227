import { gatewayService } from 'src/services/gatewayService';

/**
 * A function for react-query to get the list of stations
 * @author Kevin Parkinson
*/
export const getStationsQueryFn = () => () => gatewayService.getStations().then((data) => data);

/**
 * A function for react-query to get the logged in user
 * @author Kevin Parkinson
 * @param {string} email - the user's email
*/
export const getUserQueryFn = (email: string) => () => gatewayService.getUser(email).then((data) => data);
