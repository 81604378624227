// React
import { useRef, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Material UI
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Tooltip,
  Typography
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

// Custom components and types
import GatewayStation, { classOfTradeDescription } from 'src/types/gateway/gatewayStation';
import GatewayUser, { userFullName, userHasStationsAssigned } from 'src/types/gateway/gatewayUser';
import Brand from 'src/types/brand';

// Store
import type { RootState } from 'src/store';
import { useSelector, useDispatch } from 'src/store';
import { clearState } from 'src/slices/userDetails';

// Services
import { gatewayService } from 'src/services/gatewayService';

// Hools and Utils
import useAuth from 'src/hooks/useAuth';
import UserIcon from 'src/icons/User';
import { appConfig } from 'src/config/config';
import { useTranslation } from 'react-i18next';
import NoItemsMessage from '../NoItemsMessage';
import logger from 'src/logging/logger';
import { getLogMsg, getVerboseLogMsg } from 'src/utils/loggingUtils';
import { removeUserActivityDateTime } from 'src/utils/cookieUtils';

const AccountPopover: FC = () => {
  // State variables
  const [open, setOpen] = useState<boolean>(false);

  // Store
  const gatewayUser = useSelector((state: RootState): GatewayUser | null => state.userDetails.gatewayUserDetails);
  const selectedStation = useSelector((state: RootState): GatewayStation | null => state.userDetails.selectedStation);
  const selectedBrand = useSelector((state: RootState): Brand | null => state.userDetails.selectedBrand);

  // Hooks and Utils
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { logout } = useAuth();
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const { brand, address, site, operatingModel } = appConfig.i18n.sectionHeaders;
  const { otrDealer } = appConfig.i18n.messages;
  const componentName = 'AccountPopover';

  // Event handlers
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      removeUserActivityDateTime();
      handleClose();
      dispatch(clearState()); // Clear Redux state
      window.localStorage.removeItem('persist:root'); // Clear Redux state persisted to local storage
      logger.verbose(getVerboseLogMsg(componentName, 'handleLogout', 'logging out'));
      await logout();
    } catch (err) {
      gatewayService.logErrorActivity(err);
      logger.error(getLogMsg(`${componentName} - handleLogout`, err));
    }
  };

  return (
    <>
      <Tooltip title={translate(appConfig.i18n.actions.account)}>
        <Box
          component={ButtonBase}
          onClick={handleOpen}
          ref={anchorRef}
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <SettingsIcon color="primary" />
        </Box>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            p: 1,
            backgroundColor: 'background.paper',
            width: 240
          }
        }}
      >
        <Box sx={{ p: 1 }}>
          <Grid container>
            {gatewayUser && !selectedStation && !userHasStationsAssigned(gatewayUser)
            && (
              <NoItemsMessage errorMessageKey={appConfig.i18n.messages.noStationsAssigned} />
            )}
            <Grid
              item
              xs={12}
            >
              <Typography
                color="GrayText"
                variant="subtitle1"
                fontWeight="700"
              >
                {userFullName(gatewayUser)}
              </Typography>
            </Grid>
            {gatewayUser && (gatewayUser.is_admin || gatewayUser.is_tm || gatewayUser.is_super)
            && (
              <>
                <Grid
                  item
                  xs={12}
                >
                  <Typography
                    color="GrayText"
                    variant="caption"
                  >
                    {gatewayUser.is_admin && translate(appConfig.i18n.roles.admin)}
                    {gatewayUser.is_tm && translate(appConfig.i18n.roles.territoryManager)}
                    {gatewayUser.is_super && translate(appConfig.i18n.roles.super)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    pb: 2
                  }}
                >
                  <Typography
                    color="GrayText"
                    variant="caption"
                  >
                    {gatewayUser.email}
                  </Typography>
                </Grid>
              </>
            )}
            {gatewayUser && !(gatewayUser.is_admin || gatewayUser.is_tm || gatewayUser.is_super)
            && (
              <Grid
                item
                xs={12}
                sx={{
                  pb: 2
                }}
              >
                <Typography
                  color="GrayText"
                  variant="caption"
                >
                  {gatewayUser.email}
                </Typography>
              </Grid>
            )}
            {selectedBrand && selectedStation
            && (
              <Grid
                item
                xs={12}
              >
                {selectedBrand.brand
                && (
                  <Box
                    sx={{
                      pb: 1.5
                    }}
                  >
                    <Typography
                      color="GrayText"
                      variant="caption"
                    >
                      <Box style={{ fontWeight: 'bold' }}>
                        {`${translate(brand)}:`}
                      </Box>
                      {selectedBrand.brand}
                    </Typography>
                  </Box>
                )}
                {selectedStation.site_description
                && (
                  <Box
                    sx={{
                      pb: 1.5
                    }}
                  >
                    <Typography
                      color="GrayText"
                      variant="caption"
                    >
                      <Box style={{ fontWeight: 'bold' }}>
                        {`${translate(site)}:`}
                      </Box>
                      {`${selectedStation.site_id} - ${selectedStation.site_description}`}
                    </Typography>
                  </Box>
                )}
                {selectedStation.address && selectedStation.city && selectedStation.province
                && (
                  <Box
                    sx={{
                      pb: 1.5
                    }}
                  >
                    <Typography
                      color="GrayText"
                      variant="caption"
                    >
                      <Box style={{ fontWeight: 'bold' }}>
                        {`${translate(address)}:`}
                      </Box>
                      <Box>{selectedStation.address}</Box>
                      <Box>{`${selectedStation.city}, ${selectedStation.province}`}</Box>
                    </Typography>
                  </Box>
                )}
                {selectedStation.operating_model
                && (
                  <Box>
                    <Typography
                      color="GrayText"
                      variant="caption"
                    >
                      <Box style={{ fontWeight: 'bold' }}>
                        {`${translate(operatingModel)}:`}
                      </Box>
                      <Box>
                        {classOfTradeDescription(selectedStation.operating_model)}
                        {selectedStation.is_otr_dealer && ` (${translate(otrDealer)})`}
                      </Box>
                    </Typography>
                  </Box>
                )}
              </Grid>
            )}
          </Grid>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem
            component={RouterLink}
            to="/profile"
          >
            <ListItemIcon>
              <UserIcon
                fontSize="small"
                color="primary"
              />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="GrayText"
                  variant="subtitle2"
                >
                  {translate(appConfig.i18n.pageTitles.profile)}
                </Typography>
              )}
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            fullWidth
            onClick={handleLogout}
            variant="outlined"
            color="primary"
          >
            {translate(appConfig.i18n.actions.logout)}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
