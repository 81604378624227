type UserActivityType = 'All'
// | 'changelanguage'
// | 'changestation'
| 'downloadplanogram'
| 'downloadreportpdf'
// | 'exporttoexcel'
| 'error'
| 'initialsettings'
| 'login'
| 'logout'
| 'pageview'
// | 'returntoadminview'
| 'search'
| 'sharearticle'
| 'submithelprequest'
| 'submitserviceticket'
// | 'updateuserpreferences'
// | 'useractivityexpired'
| 'viewarticle'
| 'viewreport';

// this array is used for displaying to the user
// therefore, the order of this array needs to be the same as above
export const userActivityTypeArray = [
  'All',
  // 'Change Language',
  // 'Change Station',
  'Download Planogram',
  'Download PDF Report',
  // 'Export to Excel',
  'Error',
  'Initial Settings',
  'Login',
  'Logout',
  'Page View',
  // 'Return to Admin View',
  'Search',
  'Share Article',
  'Submit Help Request',
  'Submit Service Ticket',
  // 'Update User Preferences',
  // 'User Activity Expired',
  'View Article',
  'View Report'
];

export const activityTypes = {
  all: 'All',
  // changelanguage: 'changelanguage',
  // changestation: 'changestation',
  downloadplanogram: 'downloadplanogram',
  downloadpdfreport: 'downloadpdfreport',
  // exporttoexcel: 'exporttoexcel',
  error: 'error',
  initialsettings: 'initialsettings',
  login: 'login',
  logout: 'logout',
  pageview: 'pageview',
  // returntoadminview: 'returntoadminview',
  search: 'search',
  sharearticle: 'sharearticle',
  submithelprequest: 'submithelprequest',
  submitserviceticket: 'submitserviceticket',
  // updateuserpreferences: 'updateuserpreferences',
  // useractivityexpired: 'useractivityexpired',
  viewarticle: 'viewarticle',
  viewreport: 'viewreport'
};

export const translateActivityType = (friendlyActivityTypeName: string): UserActivityType => friendlyActivityTypeName.replaceAll(' ', '').toLowerCase() as UserActivityType;

export default UserActivityType;
