interface Brand {
  brand: string;
  mdmCode: string;
  logoLarge: string;
  websiteUrl: string;
}

/**
 * Returns a Brand with all fields set to empty
 * @author Kevin Parkinson
*/
export const getEmptyBrand = () => ({
  brand: '',
  mdmCode: '',
  logoLarge: '',
  websiteUrl: ''
});

export default Brand;
