import { combineReducers } from '@reduxjs/toolkit';
import { reducer as userDetailsReducer } from 'src/slices/userDetails';
import { reducer as contentfulLookupReducer } from 'src/slices/contentfulLookup';

const rootReducer = combineReducers({
  userDetails: userDetailsReducer,
  contentfulLookups: contentfulLookupReducer
});

export default rootReducer;
