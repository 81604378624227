export enum FavouriteToggle { Favourite, Unfavourite }

export enum Category {
  CriticalUpdates = 'Critical Updates',
  Announcements = 'Announcements',
  LatestUpdates = 'Latest Updates',
  SafetySecurityCompliance = 'Safety, Security and Compliance',
  Merchandising = 'Merchandising',
  Financial = 'Financial',
  SiteOperations = 'Site Operations',
  CustomerExperience = 'Customer Experience',
  Marketing = 'Marketing',
  Training = 'Training'
}
export enum CategoryPages {
  SafetySecurityCompliance = 'safetysecuritycompliance',
  Merchandising = 'merchandising',
  Financial = 'financial',
  SiteOperations = 'siteoperations',
  CustomerExperience = 'customerexperience',
  Marketing = 'marketing',
  Training = 'training',
}
