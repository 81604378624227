import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
import { appConfig } from 'src/config/config';
import LogoProps from 'src/types/props/logoProps';

import useMediaQuery from '@material-ui/core/useMediaQuery';

const Logo: FC<LogoProps> = ({ showSmall }: LogoProps) => {
  const islargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  if (islargeScreen && !showSmall) {
    return (
      <img
        src={`${process.env.PUBLIC_URL}/static/${appConfig.images.parklandWordmarkLogo}`}
        alt="Home"
      />
    );
  }
  return (
    <img
      src={`${process.env.PUBLIC_URL}/static/${appConfig.images.parklandLogo}`}
      alt="Home"
    />
  );
};

export default Logo;
