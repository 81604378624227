import { appConfig } from 'src/config/config';

/**
 * Gets the number of hours in milliseconds the stations should be cached for
 * @author Kevin Parkinson
*/
export const getStationsStaleTime = (): number => Number(appConfig.caching.stationCachingHours) * 60 * 60 * 1000;

/**
 * Gets the number of hours in milliseconds the stations should be cached for
 * @author Kevin Parkinson
*/
export const getUserStaleTime = (): number => Number(appConfig.caching.userCachingMinutes) * 60 * 1000;
