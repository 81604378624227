import { Attachment } from 'src/types/attachment';

/**
 * Converts a list of attachments in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {any[]} attachments - the Contentful attachments to convert
 * @return {Attachment[]}
*/
export const simplifyContentfulAttachmentsAny = (attachments: any[]): Attachment[] => attachments.map((item) => ({
  url: item && item.fields && item.fields.file && item.fields.file.url ? item.fields.file.url : '',
  title: item && item.fields && item.fields.title ? item.fields.title : ''
}));
