import GatewayStation, { classOfTradeCode } from 'src/types/gateway/gatewayStation';

/**
 * Answers the question whether you should go ahead and try filtering content by brand, region and classOfTrade
 * @author Kevin Parkinson
 * @param station (GatewayStation) the user's selected station (should be non-exitent for admin, TM or user's who are not yet assigned a station)
 * @param brandLookups (string[]) a list of brand strings fro contentful verify if the brand is in tthe list
 * @param classOfTradeLookups (string[]) a list of classOfTrade strings fro contentful verify if the station's operating_model is in tthe list
 * @param regionLookups (string[])a list of region strings fro contentful verify if the station's province is in tthe list
 * @returns true if you should go ahead and try filtering content by brand, region and classOfTrade
 * @purpose shortcircuits heavy lifting calls to contentful and resulting filtering on client side
*/
export const shouldIApplyFilters = (station: GatewayStation, brandLookups: string[], classOfTradeLookups: string[], regionLookups: string[]): boolean => {
  // if I don't have a station or brand, then don't apply filters
  if (!station || !station.operating_model || !station.province || !station.brand) return false;

  const shouldApplyFilters = station && station.site_id
  && brandLookups.includes(station.brand)
  && classOfTradeLookups.includes(classOfTradeCode(station))
  && regionLookups.includes(station.province);

  return shouldApplyFilters;
};
