import { useTranslation } from 'react-i18next';
import { appConfig } from 'src/config/config';

/**
 * Gets a page title in a standard format
 * @author Kevin Parkinson
 * @param {string} pageName - the name of the specific page
*/
const pageTitle = (pageName: string): string => {
  const { t: translate } = useTranslation();
  return `${translate(pageName)} | ${translate(appConfig.i18n.application.appName)} ${process.env.NODE_ENV !== 'production' ? process.env.NODE_ENV.toUpperCase() : ''}`;
};

export default pageTitle;
