// React
import type { FC } from 'react';

// Material UI
import {
  Box,
  List,
  ListItemText,
  Typography,
} from '@material-ui/core';

// Store
import type { RootState } from 'src/store';
import { useSelector } from 'src/store';

// Custom types
import GatewayStation from 'src/types/gateway/gatewayStation';

// Hooks, Config and utils
import { useTranslation } from 'react-i18next';
import { appConfig } from 'src/config/config';

const NoContentBanner: FC = () => {
  // Hooks and utils
  const { t: translate } = useTranslation();
  const selectedStation = useSelector((state: RootState): GatewayStation | null => state.userDetails.selectedStation);

  // Constants
  const {
    noStationSelected,
    siteNotConfiguredCorrectly,
    reasonNoSelectedStation,
    reasonEmptyRegion,
    reasonIllCnfiguredRegion,
    reasonEmptyOperatingModel,
    reasonIllConfiguredOperatingModel,
    reasonEmptyBrand,
    reasonIllConfiguredBrand,
    listOfIllConfiguredReasons
  } = appConfig.i18n.messages;

  // Functions
  const getIllConfiguredReasons = (): string[] => {
    const reasons = [];
    if (!selectedStation) reasons.push(translate(reasonNoSelectedStation));
    if (selectedStation && !selectedStation.province) reasons.push(translate(reasonEmptyRegion));
    if (selectedStation && selectedStation.province) reasons.push(`${translate(reasonIllCnfiguredRegion)}: ${selectedStation.province}`);
    if (selectedStation && !selectedStation.operating_model) reasons.push(translate(reasonEmptyOperatingModel));
    if (selectedStation && selectedStation.operating_model) reasons.push(`${translate(reasonIllConfiguredOperatingModel)}: ${selectedStation.operating_model}`);
    if (selectedStation && !selectedStation.brand) reasons.push(translate(reasonEmptyBrand));
    if (selectedStation && selectedStation.brand) reasons.push(`${translate(reasonIllConfiguredBrand)}: ${selectedStation.brand}`);
    return reasons;
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        height: '100%',
        justifyContent: 'space-between',
        verticalAlign: 'top',
        px: 2,
        py: 2
      }}
    >
      {selectedStation && !selectedStation.site_id
      && (
        <Typography
          color="red"
          variant="h6"
        >
          {translate(noStationSelected)}
        </Typography>
      )}
      {selectedStation && selectedStation.site_id
      && (
        <>
          <Typography
            color="red"
            variant="h6"
            display="block"
          >
            {translate(siteNotConfiguredCorrectly)}
          </Typography>
          <Typography
            color="red"
            variant="h6"
            display="block"
          >
            {`${translate(listOfIllConfiguredReasons)}:`}
          </Typography>
          <List disablePadding>
            {getIllConfiguredReasons().map((reason: string) => (
              <ListItemText
                key={reason}
                sx={{
                  pl: 2
                }}
                primary={(
                  <Typography
                    color="red"
                    variant="h6"
                  >
                    {`- ${reason}`}
                  </Typography>
                )}
              />
            ))}
          </List>
        </>
      )}
    </Box>
  );
};

export default NoContentBanner;
