import { EntryCollection } from 'contentful';
import ContentfulSubcategory from 'src/types/contentful/contentfulSubcategory';

/**
 * Converts a list of subCategories in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {EntryCollection<ContentfulSubcategory>} subCategories - the Contentful subCategories to convert
 * @return {string[]}
*/
export const simplifyContentfulSubcategories = (subCategories: EntryCollection<ContentfulSubcategory>): string[] => subCategories.items.map((item) => (item && item.fields && item.fields.subCategory ? item.fields.subCategory : ''));
