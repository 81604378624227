import { appConfig } from 'src/config/config';

const {
  REACT_APP_PROD_HOST_NAME: productionHostName,
  NODE_ENV: nodeEnv,
  REACT_APP_CONTENTFUL_ENVIRONMENT: contentfulEnv
} = process.env;
const {
  dev,
  test,
  prod,
  builds
} = appConfig.environments;
const { hostname } = window.location;

export const isProduction = () => window.location.hostname === productionHostName && nodeEnv === builds.production && contentfulEnv === prod.contentfulEnvironment;

export const isDevOrTest = () => nodeEnv === builds.development || (hostname === dev.hostName && contentfulEnv === dev.contentfulEnvironment) || (hostname === test.hostName && contentfulEnv === test.contentfulEnvironment);

export const isDevelopment = () => nodeEnv === builds.development || hostname === dev.hostName;
