// REDUX
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

// Custom types
import type { AppThunk } from 'src/store';
import GatewayUser, { getEmptyUser } from 'src/types/gateway/gatewayUser';
import GatewayStation, { getEmptyStation } from 'src/types/gateway/gatewayStation';
import Brand, { getEmptyBrand } from 'src/types/brand';
import UninitialisedUserType from 'src/types/uninitialisedUserType';
import UserDetailsState, { initialState } from 'src/types/userDetailsState';

// Hooks and utils
import logger from 'src/logging/logger';

const className = 'User Details Store';

const slice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    setUpdatedDate(
      state: UserDetailsState
    ): void {
      state.updatedDateTime = new Date().toString();
    },
    setShowContent(
      state: UserDetailsState,
      action: PayloadAction<boolean>
    ): void {
      state.showContent = action.payload;
    },
    setApplyFilters(
      state: UserDetailsState,
      action: PayloadAction<boolean>
    ): void {
      state.applyFilters = action.payload;
    },
    setUninitialisedUserType(
      state: UserDetailsState,
      action: PayloadAction<UninitialisedUserType>
    ): void {
      state.uninitialisedUserType = action.payload;
    },
    setUser(
      state: UserDetailsState,
      action: PayloadAction<GatewayUser>
    ): void {
      state.gatewayUserDetails = action.payload;
    },
    saveUserBookmark(
      state: UserDetailsState,
      action: PayloadAction<string>
    ): void {
      const articleID = action.payload;
      state.gatewayUserDetails.bookmarks.push(articleID);
    },
    deleteUserBookmark(
      state: UserDetailsState,
      action: PayloadAction<string>
    ): void {
      const articleID = action.payload;
      const filteredBookmarks = state.gatewayUserDetails.bookmarks.filter((bm) => bm !== articleID);
      state.gatewayUserDetails.bookmarks = filteredBookmarks;
    },
    saveUserLanguage(
      state: UserDetailsState,
      action: PayloadAction<string>
    ): void {
      state.gatewayUserDetails.language = action.payload;
    },
    setUserTimezone(
      state: UserDetailsState,
      action: PayloadAction<string>
    ): void {
      state.gatewayUserDetails.language = action.payload;
    },
    setSelectedStation(
      state: UserDetailsState,
      action: PayloadAction<GatewayStation>
    ): void {
      state.selectedStation = action.payload;
    },
    setSelectedBrand(
      state: UserDetailsState,
      action: PayloadAction<Brand>
    ): void {
      state.selectedBrand = action.payload;
    },
    clearState(
      state: UserDetailsState
    ): void {
      state.updatedDateTime = null;
      state.gatewayUserDetails = getEmptyUser();
      state.selectedBrand = getEmptyBrand();
      state.selectedStation = getEmptyStation();
    }
  }
});

export const { reducer } = slice;

/**
 * Sets a variable to the current datetime to alert subscribers that is time to update
 * @author Kevin Parkinson
*/
export const setUpdatedDate = (): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(slice.actions.setUpdatedDate());
  } catch (error) {
    logger.error(className, error);
  }
};

/**
 * Sets true or falses whether to apply filters to user content
 * @author Kevin Parkinson
 * @param {boolean} applyFilters
*/
export const setApplyFilters = (applyFilters: boolean): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(slice.actions.setApplyFilters(applyFilters));
  } catch (error) {
    logger.error(className, error);
  }
};

/**
 * Sets true or false whether to show  content to user
 * @author Kevin Parkinson
 * @param {boolean} showContent
*/
export const setShowContent = (showContent: boolean): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(slice.actions.setShowContent(showContent));
  } catch (error) {
    logger.error(className, error);
  }
};

/**
 * Sets a value for what kind of user is logging in for the first time
 * @author Kevin Parkinson
 * @param {boolean} showContent
*/
export const setUninitialisedUserType = (type: UninitialisedUserType): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(slice.actions.setUninitialisedUserType(type));
  } catch (error) {
    logger.error(className, error);
  }
};

/**
 * Sets the main user details in state
 * @author Kevin Parkinson
 * @param {GatewayUser} initializedUser
*/
export const setUser = (initializedUser: GatewayUser): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(slice.actions.setUser(initializedUser));
  } catch (error) {
    logger.error(className, error);
  }
};

/**
 * Changes the user's language
 * @author Kevin Parkinson
 * @param {string} language
*/
export const saveUserLanguage = (language: string): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(slice.actions.saveUserLanguage(language));
  } catch (error) {
    logger.error(className, error);
  }
};

/**
 * Adds a user's bookmark to state
 * @author Kevin Parkinson
 * @param {string} articleID
*/
export const saveUserBookmark = (articleID: string): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(slice.actions.saveUserBookmark(articleID));
  } catch (error) {
    logger.error(className, error);
  }
};

/**
 * Deletes a user's bookmark in state
 * @author Kevin Parkinson
 * @param {string} articleID
*/
export const deleteUserBookmark = (articleID: string): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(slice.actions.deleteUserBookmark(articleID));
  } catch (error) {
    logger.error(className, error);
  }
};

/**
 * Set the user's selected station in state
 * @author Kevin Parkinson
 * @param {GatewayStation} station
*/
export const setSelectedStation = (station: GatewayStation): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(slice.actions.setSelectedStation(station));
  } catch (error) {
    logger.error(className, error);
  }
};

/**
 * Set the user's selected brand in state
 * @author Kevin Parkinson
 * @param {Brand} brand
*/
export const setSelectedBrand = (brand: Brand): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(slice.actions.setSelectedBrand(brand));
  } catch (error) {
    logger.error(className, error);
  }
};

/**
 * Clears the user details state
 * @author Kevin Parkinson
*/
export const clearState = (): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(slice.actions.clearState());
  } catch (error) {
    console.error(className, error);
  }
};

export default slice;
