import { Entry } from 'contentful';
import ContentfulBrand from 'src/types/contentful/contentfulBrand';
import Brand from 'src/types/brand';

/**
 * Converts a brand in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {Entry<ContentfulBrand>} brand - the Contentful brand to convert
 * @return {Brand}
*/
export const simplifyContentfulBrand = (brand: Entry<ContentfulBrand>): Brand => ({
  brand: brand && brand.fields && brand.fields.brand ? brand.fields.brand : '',
  mdmCode: brand && brand.fields && brand.fields.mdmCode ? brand.fields.mdmCode : '',
  logoLarge: brand && brand.fields && brand.fields.logoLarge && brand.fields.logoLarge.fields && brand.fields.logoLarge.fields.file && brand.fields.logoLarge.fields.file.url ? brand.fields.logoLarge.fields.file.url : '',
  websiteUrl: brand && brand.fields && brand.fields.websiteUrl ? brand.fields.websiteUrl : ''
});

/**
 * Converts a list of brand in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {any[]} brands - the Contentful brands to convert
 * @return {string[]}
*/
export const simplifyContentfulBrandsAny = (brands: any[]): string[] => brands.map((item) => (item && item.fields && item.fields.brand ? item.fields.brand : ''));
