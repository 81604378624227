/**
 * TODO: describe function
 * @author Kevin Parkinson
 * @param {string} method
 * @param {any} err
*/
export const getLogMsg = (method: string, err: any) => `[${window.location.pathname} - ${method}]: ${err}`;

/**
 * TODO: describe function
 * @author Kevin Parkinson
 * @param TODO: fill out params
*/
export const getVerboseLogMsg = (component: string, method: string, message: string) => `route: ${window.location.pathname} | component/page: ${component} | method: ${method} | ${message}`;
