// React
import type { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// Custom types
import AuthGuardProps from 'src/types/props/authGaurdProps';
import { User } from 'src/types/gateway';

// Store
import { RootState, useSelector } from 'src/store';

// Hooks and utils
import { useState } from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { appConfig } from 'src/config/config';

const AdminGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const { login } = useAuth() as any;

  // Selectors
  const gatewayUser = useSelector((state: RootState): User | null => state.userDetails.gatewayUserDetails);

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    login();
  }

  if (!gatewayUser || (!gatewayUser.is_admin && !gatewayUser.is_super)) {
    return <Navigate to={appConfig.routes.notAuthorised} />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AdminGuard.propTypes = {
  children: PropTypes.node
};

export default AdminGuard;
