// React
import type { FC } from 'react';

// Material UI
import {
  Box,
  Typography
} from '@material-ui/core';

// Custom Types
import { NoItemsMessageProps, noItemsMessagePropTypes } from 'src/types/props/noItemsMessageProps';

// Hooks, Config and utils
import { useTranslation } from 'react-i18next';

const NoItemsMessage: FC<NoItemsMessageProps> = ({ errorMessageKey, additionalErrorInfo }: NoItemsMessageProps) => {
  const { t: translate } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        height: '100%',
        justifyContent: 'space-between',
        verticalAlign: 'top',
        px: 2,
        py: 2
      }}
    >
      <Typography
        color="GrayText"
        variant="caption"
      >
        {`${translate(errorMessageKey)}${additionalErrorInfo ?? ''}`}
      </Typography>
    </Box>
  );
};

NoItemsMessage.propTypes = noItemsMessagePropTypes;

export default NoItemsMessage;
