// Hooks and utils
import { addMonths, format } from 'date-fns';
import { appConfig } from 'src/config/config';
import _ from 'lodash';

// Custom Types
import MysteryShopperReportItem from 'src/types/mysteryShopperReportItem';
import GatewayStationSummary from 'src/types/gateway/gatewayStationSummary';
import MysteryShopperScoreOverTimeItem from 'src/types/mysteryShopperScoreOverTimeItem';

// Constants
const {
  section,
  previousScore,
  currentScore,
  difference,
  bu,
  documentDate,
  city,
  province,
  fuelBrand,
  operatingModel,
  rom,
  tm,
  overallScore,
  interiorScore,
  exteriorScore,
  washroomScore,
  customerService,
  loyaltyAsk,
  loyaltyOffer,
  upsellProduct
} = appConfig.i18n.formFields;
export const nonApplicable = 'N/A';

export const scoreComparisonCategories = [
  'Overall',
  'InteriorCleanliness',
  'ExteriorCleanliness',
  'WashroomCleanliness',
  'CustomerService',
  'LoyaltyAsk',
  'LoyaltyOffer',
  'UpsellProduct',
];
export const scoreComparisonCategoryEnum = {
  overall: 'Overall',
  interiorCleanliness: 'Interior Cleanliness',
  exteriorCleanliness: 'Exterior Cleanliness',
  washroomCleanliness: 'Washroom Cleanliness',
  customerService: 'Customer Service',
  loyaltyAsk: 'Loyalty Ask',
  loyaltyOffer: 'Loyalty Offer',
  upsellProduct: 'Upsell Product',
};

// Tabular data
export const scoreComparisonColumnKeys = [
  section,
  previousScore,
  currentScore,
  difference,
];

export const scoreComparisonColumns = {
  section: 'section',
  previousScore: 'previousScore',
  currentScore: 'currentScore',
  difference: 'difference'
};

export const scoreDetailsColumnKeys = [
  bu,
  documentDate,
  city,
  province,
  fuelBrand,
  operatingModel,
  rom,
  tm,
  overallScore,
  interiorScore,
  exteriorScore,
  washroomScore,
  customerService,
  loyaltyAsk,
  loyaltyOffer,
  upsellProduct
];

export const scoreDetailsColumns = {
  bu: 'bu',
  documentDate: 'documentDate',
  shopType: 'shopType',
  city: 'city',
  province: 'province',
  fuelBrand: 'fuelBrand',
  operatingModel: 'operatingModel',
  rom: 'rom',
  tm: 'tm',
  overallScore: 'overallScore',
  interiorScore: 'interiorScore',
  exteriorScore: 'exteriorScore',
  washroomScore: 'washroomScore',
  customerService: 'customerServiceScore',
  loyaltyAsk: 'loyaltyAsk',
  loyaltyOffer: 'loyaltyOffer',
  upsellProduct: 'upsellProductService'
};

export const scoreDetailsSortByColumns = [
  {
    value: 'bu',
    text: 'BU'
  },
  {
    value: 'documentDate',
    text: 'Document Date'
  },
  {
    value: 'shopType',
    text: 'Shop Typee'
  },
  {
    value: 'city',
    text: 'City'
  },
  {
    value: 'province',
    text: 'Province'
  },
  {
    value: 'fuelBrand',
    text: 'Fuel Brand'
  },
  {
    value: 'operatingModel',
    text: 'Operating Model'
  },
  {
    value: 'rom',
    text: 'ROM'
  },
  {
    value: 'tm',
    text: 'TM'
  },
  {
    value: 'overallScore',
    text: 'Overall Score'
  },
  {
    value: 'interiorScore',
    text: 'Interior Score'
  },
  {
    value: 'exteriorScore',
    text: 'Exterior Score'
  },
  {
    value: 'washroomScore',
    text: 'Washroom Score'
  },
  {
    value: 'customerService',
    text: 'Customer Service'
  },
  {
    value: 'loyaltyAsk',
    text: 'Loyalty Ask'
  },
  {
    value: 'loyaltyOffer',
    text: 'Loyalty Offer'
  },
  {
    value: 'upsellProduct',
    text: 'Upsell Product'
  }
];

// types
export type ShopType = 'MS' | 'AR' | 'TM';

// Utility Functions
export const getReportingMonths = () => {
  const today = new Date();
  const reportingMonths = [];
  for (let m = 11; m > 0; m--) {
    const prevMonthDate = addMonths(today, m * -1);
    reportingMonths.push(format(prevMonthDate, 'yyyy-MM'));
  }
  reportingMonths.push(format(today, 'yyyy-MM'));
  return reportingMonths;
};

export const getAverageScore = (scores: any[]) => {
  let sumOfScores = 0;
  let applicableScoresCount = 0;
  for (let s = 0; s < scores.length; s++) {
    const score = scores[s];
    if (score && !Number.isNaN(score)) {
      sumOfScores += Number(score);
      applicableScoresCount++;
    }
  }
  const percent = sumOfScores / applicableScoresCount;
  return `${percent.toFixed(2)}%`;
};

export const getPassRatePercentage = (scores: any[]) => {
  const passingScores = scores.filter((s) => Number(s) >= 50).length;
  const percent = (passingScores / scores.length) * 100;
  return `${percent.toFixed(2)}%`;
};

export const getAverageScoreAsNumber = (scores: any[]): number => {
  if (scores.length === 0) return 0;
  let sumOfScores = 0;
  let applicableScoresCount = 0;
  for (let s = 0; s < scores.length; s++) {
    const score = scores[s];
    if (score && !Number.isNaN(score)) {
      sumOfScores += Number(score);
      applicableScoresCount++;
    }
  }
  const percent = sumOfScores / applicableScoresCount;
  return percent;
};

export const getMysteryShopsAverage = (items: MysteryShopperReportItem[]) => {
  const msScores = items.filter((i) => i.shopType === 'MS').map((i) => Number(i.overallScore));
  const averageScore = getAverageScoreAsNumber(msScores);
  return `${averageScore.toFixed(2)}%`;
};

export const getAgeRestrictedShopsAverage = (items: MysteryShopperReportItem[]) => {
  const arScores = items.filter((i) => i.shopType === 'AR').map((i) => Number(i.overallScore));
  const averageScore = getAverageScoreAsNumber(arScores);
  return `${averageScore.toFixed(2)}%`;
};

export const getScoreInCategory = (category: string, item: MysteryShopperReportItem) => {
  let currentScoreInCat;
  switch (category) {
    case scoreComparisonCategoryEnum.customerService:
      currentScoreInCat = item.customerServiceScore;
      break;
    case scoreComparisonCategoryEnum.exteriorCleanliness:
      currentScoreInCat = item.exteriorScore;
      break;
    case scoreComparisonCategoryEnum.interiorCleanliness:
      currentScoreInCat = item.interiorScore;
      break;
    case scoreComparisonCategoryEnum.loyaltyAsk:
      currentScoreInCat = item.loyaltyAsk;
      break;
    case scoreComparisonCategoryEnum.loyaltyOffer:
      currentScoreInCat = item.loyaltyOffer;
      break;
    case scoreComparisonCategoryEnum.overall:
      currentScoreInCat = item.overallScore;
      break;
    case scoreComparisonCategoryEnum.upsellProduct:
      currentScoreInCat = item.upsellProductService;
      break;
    case scoreComparisonCategoryEnum.washroomCleanliness:
      currentScoreInCat = item.washroomScore;
      break;
    default:
      currentScoreInCat = item.overallScore;
  }
  return currentScoreInCat;
};

export const toPercentFromUnknown = (value: any) => {
  if (!value || value === nonApplicable) return nonApplicable;
  const num = Number(value);
  if (Number.isNaN(num)) return nonApplicable;
  return `${Number(value).toFixed(2)}%`;
};

export const numberToPercent = (value: number | 'N/A') => {
  if (!value || value === nonApplicable || Number.isNaN(value)) return nonApplicable;
  return `${Number(value).toFixed(2)}%`;
};

export const toPercent = (value: string) => {
  const num = Number(value);
  return (Number.isNaN(num) ? nonApplicable : `${num.toFixed(2)}%`);
};

export const getDataForStation = (siteID: string, data: MysteryShopperReportItem[]) => data.filter((d) => d.bu === siteID);

export const getCurrentScoreInCategory = (category: string, data: MysteryShopperReportItem[], type: ShopType) => {
  if (!data || data.length === 0) return nonApplicable;
  const dataByShopType = data.filter((d) => d.shopType === type);
  if (!dataByShopType || dataByShopType.length === 0) return nonApplicable;
  const currentRecord = _.maxBy(dataByShopType, (d) => d.documentDate);
  return currentRecord ? getScoreInCategory(category, currentRecord) : nonApplicable;
};

export const getCurrentScoreInCategoryAsPercent = (category: string, data: MysteryShopperReportItem[], type: ShopType) => {
  if (!data || data.length === 0) return nonApplicable;
  const dataByShopType = data.filter((d) => d.shopType === type);
  if (!dataByShopType || dataByShopType.length === 0) return nonApplicable;
  const currentRecord = _.maxBy(dataByShopType, (d) => d.documentDate);
  return currentRecord ? toPercent(getScoreInCategory(category, currentRecord)) : nonApplicable;
};

export const getCurrentScoreForAllStationsInCategory = (category: string, stationsIDs: string[], data: MysteryShopperReportItem[], type: ShopType) => {
  if (!data || data.length === 0) return nonApplicable;
  const dataByShopType = data.filter((d) => d.shopType === type);
  let stationScores = 0;
  let applicableScoresCount = 0;
  for (let s = 0; s < stationsIDs.length; s++) {
    const stationData = getDataForStation(stationsIDs[s], dataByShopType);
    const currScore = getCurrentScoreInCategory(category, stationData, type);
    const currScoreAsNumber = Number(currScore);
    if (currScore && currScore !== nonApplicable) {
      applicableScoresCount++;
      stationScores += currScoreAsNumber;
    }
  }
  return (stationScores === 0 || applicableScoresCount === 0) ? nonApplicable : numberToPercent(stationScores / applicableScoresCount);
};

export const getPreviousScoreInCategory = (category: string, data: MysteryShopperReportItem[], type: ShopType) => {
  if (!data || data.length < 2) return nonApplicable;
  const dataCopy = _.cloneDeep(data);
  const dataByShopType = dataCopy.filter((d) => d.shopType === type);
  if (!dataByShopType || dataByShopType.length < 2) return nonApplicable;
  _.remove(dataByShopType, _.maxBy(dataByShopType, (d) => d.documentDate));
  const previousRecord = _.maxBy(dataByShopType, (d) => d.documentDate);
  return previousRecord ? getScoreInCategory(category, previousRecord) : nonApplicable;
};

export const getPreviousScoreInCategoryAsPercent = (category: string, data: MysteryShopperReportItem[], type: ShopType) => {
  if (!data || data.length < 2) return nonApplicable;
  const dataCopy = _.cloneDeep(data);
  const dataByShopType = dataCopy.filter((d) => d.shopType === type);
  if (!dataByShopType || dataByShopType.length < 2) return nonApplicable;
  _.remove(dataByShopType, _.maxBy(dataByShopType, (d) => d.documentDate));
  const previousRecord = _.maxBy(dataByShopType, (d) => d.documentDate);
  return previousRecord ? toPercent(getScoreInCategory(category, previousRecord)) : nonApplicable;
};

export const getPreviousScoreForAllStationsInCategory = (category: string, stationsIDs: string[], data: MysteryShopperReportItem[], type: ShopType) => {
  if (!data || data.length === 0) return nonApplicable;
  let stationScores = 0;
  let applicableScoresCount = 0;
  for (let s = 0; s < stationsIDs.length; s++) {
    const stationData = getDataForStation(stationsIDs[s], data);
    const prevScore = getPreviousScoreInCategory(category, stationData, type);
    const prevScoreAsNumber = Number(prevScore);
    if (prevScore && prevScore !== nonApplicable) {
      applicableScoresCount++;
      stationScores += prevScoreAsNumber;
    }
  }
  return (stationScores === 0 && applicableScoresCount === 0) ? nonApplicable : numberToPercent(stationScores / applicableScoresCount);
};

export const getCurrentAndPreviousScoreDifferenceInCategory = (category: string, data: MysteryShopperReportItem[], type: ShopType) => {
  if (!data || data.length < 2) return nonApplicable;
  const currentRecord = getCurrentScoreInCategory(category, data, type);
  const previousRecord = getPreviousScoreInCategory(category, data, type);
  const currScore = getScoreInCategory(category, currentRecord);
  const prevScore = getScoreInCategory(category, previousRecord);
  if (currScore === '' || prevScore === '') return nonApplicable;
  return Number(currScore) - Number(prevScore);
};

export const getDataForStations = (assignedStations: GatewayStationSummary[], data: MysteryShopperReportItem[]) => {
  const assignedStationIDs = assignedStations.map((s) => s.site_id);
  return data.filter((d) => assignedStationIDs.includes(d.bu));
};

export const getMysteryShopsInMonth = (dateYearMonth: string, data: MysteryShopperReportItem[]): MysteryShopperReportItem[] => {
  const moreSpecficDate = new Date(`${dateYearMonth}-01 00:00:00.000`);
  const specificYear = moreSpecficDate.getFullYear();
  const specificMonth = moreSpecficDate.getMonth();
  const mysteryShopsInMonth = data.filter((d) => new Date(d.documentDate).getFullYear() === specificYear && new Date(d.documentDate).getMonth() === specificMonth);
  return mysteryShopsInMonth;
};

export const getScoresOverTimeDataStructure = (data: MysteryShopperReportItem[]): MysteryShopperScoreOverTimeItem[] => {
  if (!data) return [];
  const scoresOverTime: MysteryShopperScoreOverTimeItem[] = [];
  const reportingMonths = getReportingMonths();
  for (let r = 0; r < reportingMonths.length; r++) {
    const mysteryShopsInMonth = getMysteryShopsInMonth(reportingMonths[r], data);
    const averageScore = getAverageScoreAsNumber(mysteryShopsInMonth.map((s) => s.overallScore));
    scoresOverTime.push({
      date: reportingMonths[r],
      averageScore,
      numberOfShops: mysteryShopsInMonth.length
    });
  }
  return scoresOverTime;
};

export const getNumberOfMysteryShopTicks = (numberOfStations: number) => (numberOfStations === 1 ? 5 : 10);

export const getMaxNumberOfMysteryShops = (numberOfStations: number) => (numberOfStations === 1 ? 5 : 60);
